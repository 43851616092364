import {useState} from 'react'
import profile from '../assets/profile_photo.jpeg';
import logo from '../assets/pwd-white.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowRight, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {  faGithub, faInstagram, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import Text from '../components/Text'

export default function Home () {
    const pwdText = '<PWD/> Pardo Web Developer';

    return (
        <>
        <div id="home" className="flex w-full h-screen flex-col md:flex-row gap-5 items-center justify-center text-white relative">
            <div className='pt-80 sm:w-5/6 sm:p-2 md:w-3/6 md:p-4 xl:p-30 lg:w-3/6 xl:w-2/6 xl:p-0 2xl:w-2/6 2xl:p-10'>
                <img className='border-2 border-teal-500 rounded-full' data-aos="flip-right" data-aos-duration="1500" data-aos-offset="200"  src={profile} alt="logo"/>
            </div>
            <div className='md:w-3/6' data-aos="fade-right" data-aos-duration="1000" data-aos-offset="100" >
                <div className="flex flex-col w-full mt-8">
                    <h1 className="text-xl text-gray-400">Hi, I'm</h1>
                    <h1 className="text-2xl font-bold">David Pardo</h1>
                    <p class="text-xl font-bold text-rose-300"><Text /></p>
                    <p className="text-md font-light text-gray-400 ">Senior front-end, web application developer and UX designer. Enthusiastic and motivated self-learning in Information Technology and Software Engineering. Extended experience in Cloud Computing, DevOps, and Web Development.</p>
                    <h1 className="font-link text-2xl font-bold">{pwdText}</h1>
                </div>
                
                <ul className='flex mt-2 gap-4 items-center'>
                    <li>
                        <a href='https://www.linkedin.com/in/david-julian-pardo/' rel="noreferrer" target="_blank"><FontAwesomeIcon size='2xl' icon={faLinkedinIn} /></a>
                    </li>
                    <li>
                        <a href='https://www.instagram.com/david.pardo.corzo/' rel="noreferrer" target="_blank"><FontAwesomeIcon size='2xl' icon={faInstagram} /></a>
                    </li>
                    <li>
                        <a href="mailto:info@pardowebdeveloper.com" rel="noreferrer" target="_blank"><FontAwesomeIcon size='2xl' icon={faEnvelope} /></a>
                    </li>
                </ul>
            </div>
        </div>
        <div id="skills" className='mb-24'></div>
        </>
    )
}
