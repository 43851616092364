import CertCard from "./CertCard.js"
import azure from "../assets/certs/az-900.png"

// import 

export default function Certs(){
    return (
        <div id="certs" className="mt-10 mb-16 mb-16 text-white">
            <h1 className="text-center text-5xl font-bold mb-16">Certifications</h1>
            {/* <div className="flex flex-col md:flex-row flex-wrap mt-4 gap-5"> */}
            <div className="grid grid-cols-1 md:grid-cols-3 justify-center mt-4 gap-5">
                <CertCard name="Azure 900 Fundamentals" img={azure} issued="Microsoft" date="Nov 2022" />
            </div>
        </div>
    )
}
