import logo from '../assets/pwd-white.png';
import cv from "../assets/cv.pdf"

export default function Navbar() {
    return (
    <div className='fixed z-50 bg-dark-500 w-full top-0 left-0 px-8 py-4 lg:px-20 xl:px-36'>
        <div className="flex justify-between items-center text-white">
            <img src={logo} className="App-logo w-20 rounded-full" alt="logo" />
            <ul className="hidden md:flex">
            <li className="p-4"><a href="#home" className="hover:underline">Home</a></li>
            <li className="p-4"><a href="#skills" className="hover:underline">Skills</a></li>
            <li className="p-4"><a href="#projects" className="hover:underline">Projects</a></li>
            <li className="p-4"><a href="#certs" className="hover:underline">Certfications</a></li>
            </ul>
            <a href={cv} rel="noreferrer" target="_blank" className=" bg-rose-500 rounded-full px-4 py-1 border border-rose-800 border border-4">Download CV</a>
        </div>
    </div>
    )
}
