import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SkillCard from "./SkillCard.js"

import angular from "../assets/skills/angular.svg"
import javascript from "../assets/skills/javascript.svg"
import typescript from "../assets/skills/typescript.svg"
import css from "../assets/skills/css.svg"
import reactIcon from "../assets/skills/react.svg"
import html from "../assets/skills/html.svg"
import stencil from "../assets/skills/stencil.svg"
import apollo from "../assets/skills/apollo.svg"
import react from "../assets/skills/react.svg"
import azure from "../assets/skills/azure.svg"
import git from "../assets/skills/git.svg"
import github from "../assets/skills/github.svg"
import rxjs from "../assets/skills/rxjs.svg"
import redux from "../assets/skills/redux.svg"

export default function Skills() {
    return (
        <div className="mt-80 sm:mt-4 text-white">
            <h1 className="text-center text-5xl font-bold mb-16">Skills</h1>
            <div className="mt-4">
                <div class="grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-3">
                  <SkillCard name="Javascript" experience="7 years" img={javascript} />
                  <SkillCard name="Typescript" experience="6 years" img={typescript} />
                  <SkillCard name="Html" experience="7 years" img={html} />
                  <SkillCard name="Css" experience="6 years" img={css} />
                  <SkillCard name="Angular" experience="6 years" img={angular} />
                  <SkillCard name="React" experience="1 year" img={reactIcon} />
                  <SkillCard name="RxJs" experience="5 year" img={rxjs} />
                  <SkillCard name="Redux" experience="3 year" img={redux} />
                  <SkillCard name="Apollo" experience="1 year" img={apollo} />
                  <SkillCard name="Stencil" experience="1 year" img={stencil} />
                  <SkillCard name="Git" experience="6 year" img={git} />
                  <SkillCard name="Github" experience="3 year" img={github} />
                  <SkillCard name="Azure" experience="2 year" img={azure} />
                </div>
            </div>
            <div id="projects" className='mb-24'/>
        </div>
    )
}